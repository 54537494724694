import React from 'react'
import {Helmet} from 'react-helmet'

export default function PageNotFound() {
  return (
    <div>
      <Helmet>
        <meta http-equiv="refresh" content="0; URL='/'" />
      </Helmet>
    </div>
  )
}
